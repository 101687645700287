import { IPassengerDetails, IDisplayOptions, ISortData, ISearchFlightRequestFSB } from "_models/booking.interface";

export class BookingConstants {
    static DEFAULT_BOOKING_SEARCH_DATA_FSB: ISearchFlightRequestFSB = {
        from: '',
        to: '',
        departDate: '',
        passengers: {
            adults: 1,
            child: 0,
            infants: 0
        },
        oneWay: true
    };
    // M (economy), W (economy premium), C (business), F (first class)
    static PASSENGER_DETAILS: IPassengerDetails[] = [
        { label: 'Adults', description: 'Over 11', type: 'adults', count: 1, min: 1 },
        { label: 'Children', description: '2 - 11', type: 'children', count: 0, min: 0 },
        { label: 'Infants', description: 'Under 2', type: 'infants', count: 0, min: 0 },
    ]

    static DEFAULT_DISPLAY_OPTIONS: IDisplayOptions = { filter: true, sort: true, search: true, performance: true, weather: true, status: true };
    static DEFAULT_SEARCH_DATA: ISearchFlightRequestFSB = {
        from: '',
        to: '',
        departDate: '',
        returnDate: '',
        passengers: {
            adults: 1,
            child: 0,
            infants: 0
        },
        oneWay: true
    }
    static DEFAULT_SORT_DATA: ISortData[] = [
        {
            label: 'Departure Time',
            value: 'dep',
            ascending: true,
            selected: false
        },
        {
            label: 'Price',
            value: 'price',
            ascending: true,
            selected: false
        },
        {
            label: 'Duration',
            value: 'dur',
            ascending: true,
            selected: false
        }
    ];

    static dateFormat: string = "MM DD YYYY";
    static dateFormat2: string = "DD MMM, YYYY";
    static BOOKING_IFRAME_URL: string = "https://uat.airlegit.com/search-flights";
}



