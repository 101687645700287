
import { ILink, IALAddress, ISocialMedia, IGender, ILanguage, IAncillaryProductInfo, IWeatherDescription } from "_models/common.interface";
import { FaLinkedinIn, FaWhatsapp, FaYoutube, FaEnvelope } from 'react-icons/fa';
import { ILoginForm, IContactForm, IDateGroup, IContactUsModel } from "_models/common.interface";
import { ILogoData } from '_models/main.interface';

export class CommonConstants {
    static COLOR_BACKGROUND_BRIGHTNESS = getComputedStyle(document.documentElement).getPropertyValue('--color-background-brightness');

    static EMAIL_REGEX: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    static PHONE_REGEX: RegExp = /^[1-9]\d{4,14}$/;


    static DEFAULT_SEARCH_DATE: IDateGroup = {
        startDate: '',
        endDate: '',
        minStartDate: '',
        maxStartDate: '',
        minEndDate: '',
        maxEndDate: ''
    }

    static NG_UI_URL: string | undefined = `${process.env.REACT_APP_V1_WEBSITE}/app`;
    static V1_UI_URL: string | undefined = `${process.env.REACT_APP_V1_WEBSITE}`;

    static INSURANCE_SAMPLE_DEMO: string = `${this.NG_UI_URL}/insurance-terms`;
    static FLIGHT_WATCHER_LINK: string = `/product/flight-watcher`;
    static INSURANCE_LINK: string = `/product/insurance`;
    static SUBSCRIPTION_AGREEMENT_LINK = `/terms/subscriptions`;
    static PRIVACY_LINK = `/privacy`;
    static DASHBOARD_REGISTRATION_LINK: string = `${this.NG_UI_URL}/dashboard/register`;
    static DASHBOARD_V1_LINK: string = `${this.NG_UI_URL}/dashboard/login`;
    static FG48_LINK: string = `https://flightguard48.com`;
    static FG48_TERMS_LINK: string = `${this.FG48_LINK}/terms.html`;

    static DEFAULT_LOGIN_FORM: ILoginForm = { uid: '', credential: '' };
    static DEFAULT_CONTACT_FORM: IContactForm = { name: '', mail: '', message: '', info: '' };
    static DEFAULT_CONTACTUS_DATA: IContactUsModel = { name: '', companyName: '', mail: '', phone: '', message: '', info: window.location.href };

    static CODE_TEMPLATE_DMS_CODE: string = '8adb758730ac4d3d98101c9e3d2a9b15';

    static GENDERS: IGender[] = [
        { value: '', label: 'Select' },
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' },
        // { value: 'O', label: 'Other' },
    ];
    static LANGUAGES: ILanguage[] = [
        { value: '', label: 'Select' },
        { value: 'en', label: 'English' },
    ];

    static WEATHER_DESC: IWeatherDescription[] = [
        { code: [0], desc: 'Clear sky' },
        { code: [1, 2, 3], desc: 'Mainly clear, partly cloudy, and overcast' },
        { code: [45, 48], desc: 'Fog and depositing rime fog' },
        { code: [51, 53, 55], desc: 'Drizzle: Light, moderate, and dense intensity' },
        { code: [56, 57], desc: 'Freezing Drizzle: Light and dense intensity' },
        { code: [61, 63, 65], desc: 'Rain: Slight, moderate and heavy intensity' },
        { code: [66, 67], desc: 'Freezing Rain: Light and heavy intensity' },
        { code: [71, 73, 75], desc: 'Snow fall: Slight, moderate, and heavy intensity' },
        { code: [77], desc: 'Snow grains' },
        { code: [80, 81, 82], desc: 'Rain showers: Slight, moderate, and violent' },
        { code: [85, 86], desc: '	Snow showers slight and heavy' },
        { code: [95], desc: 'Thunderstorm: Slight or moderate' },
        { code: [96, 99], desc: 'Thunderstorm with slight and heavy hail' },
    ];

    static NAVBAR_LINKS: ILink[] = [
        {
            id: '1', name: 'TOOLS', link: '', displayDropdown: false, dropdownLink: [
                { id: '1-1', name: 'CHECK FLIGHT STATUS', link: `/#flight-checker` },
                { id: '1-2', name: 'FLIGHT WATCHER', link: this.FLIGHT_WATCHER_LINK },
                { id: '1-3', name: 'AUTOMATED CHECK-IN', link: `/product/auto-checkin` },
                { id: '1-4', name: 'FLIGHTGUARD48', link: this.FG48_LINK },
                { id: '1-5', name: 'TRAVEL INSURANCE', link: this.INSURANCE_LINK }
            ]
        },
        {
            id: '2', name: 'INDUSTRIES', displayDropdown: false, link: '', dropdownLink: [
                { id: '2-1', name: 'ONLINE TRAVEL AGENCIES', link: `/industries/ota` },
                { id: '2-2', name: 'TRAVEL MANAGEMENT COMPANIES', link: `/industries/tmc` },
                { id: '2-3', name: 'TRAVEL TECHNOLOGY INNOVATORS', link: `/industries/travel-tech` },
                { id: '2-4', name: 'AIRLINES', link: `/industries/airlines` }
            ]
        },
        { id: '3', name: 'FLIGHTCHECKER', displayDropdown: true, link: `/#flight-checker`, dropdownLink: [] },
        { id: 'header-4', name: 'NEWS', displayDropdown: false, link: `/about/#news`, dropdownLink: [] },
        { id: 'header-11', name: 'PARTNERS', displayDropdown: false, link: '/partners', dropdownLink: [] },
        { id: 'contact-5', name: 'CONTACT US', displayDropdown: false, link: `/contact-us`, dropdownLink: [] },
        {
            id: 'header-6', name: 'CLIENT LOG IN', displayDropdown: false, link: '', dropdownLink: [
                { id: '6-1', name: 'DASHBOARD V1', link: this.DASHBOARD_V1_LINK },
                { id: '6-2', name: 'DASHBOARD V2', link: `/login` },
            ]
        },
        { id: 'footer-7', name: 'PRIVACY', displayDropdown: false, link: `${this.PRIVACY_LINK}`, dropdownLink: [] },
        { id: 'footer-8', name: 'SECURITY', displayDropdown: false, link: `/security`, dropdownLink: [] },
        { id: 'footer-9', name: 'TERMS', displayDropdown: false, link: `/terms`, dropdownLink: [] },
        { id: 'footer-10', name: 'ABOUT AIRLEGIT', displayDropdown: false, link: `/about`, dropdownLink: [] },
    ];

    static AIRLEGIT_ADDRESS: IALAddress = {
        name: 'AirLegit',
        line1: '1177 Avenue of the Americas',
        line2: '5th Floor',
        city: 'New York',
        state: 'New York',
        stateCode: 'NY',
        zipcode: '10036',
        country: 'United States',
        countryCode: 'USA',
        email: 'support@airlegit.com'
    };

    static SOCIAL_MEDIA: ISocialMedia[] = [
        { name: 'LinkedIn', url: 'https://www.linkedin.com/company/airlegit', icon: FaLinkedinIn },
        { name: 'WhatsApp', url: 'https://wa.me/12513130360', icon: FaWhatsapp },
        { name: 'YouTube', url: 'https://www.youtube.com/channel/UCI7FY7flMC3iXg_BM7vvRIA', icon: FaYoutube },
        { name: 'support@airlegit.com', url: 'mailto:support@airlegit.com', icon: FaEnvelope }
    ];
    static PARTNER_DETAILS_TP: ILogoData = {
        src: "/images/logos/tune-protect-logo.png",
        alt: "tuneProtect",
        link: "https://www.tuneprotect.com/emeia/our-partners/",
        width: "100px"
    }

    static ANCILLARY_PRODUCT_ARR: IAncillaryProductInfo[] = [
        {
            id: 'flight-watcher',
            prodctFeaturesId: 'FW_N',
            isActive: true,
            currency: 'USD',
            cost: 2.95,
            costType: 'trip',
            selected: [false],
            contentIcon: '/images/icons/flight-alert.svg',
            contentHeader: 'Flight Watcher',
            contentDescription:
                `<div className='description description-1'>
                    Get WhatsApp or SMS updates on your flight's status, schedule, gate changes, delay & cancellations.
                </div>`
        },
        {
            id: 'auto-checkin',
            prodctFeaturesId: 'AC_DF',
            isActive: true,
            currency: 'USD',
            cost: 7.95,
            costType: 'passenger',
            selected: [false],
            contentIcon: '/images/icons/auto-checkin.svg',
            contentHeader: 'Auto Check-in',
            contentDescription:
                `<div className='description description-1'>
                    Get boarding pass in email. Save time at the airport.
                </div>`
        },
        {
            id: 'air-traveler-assist',
            prodctFeaturesId: 'FW_N,AC_DF',
            isActive: true,
            currency: 'USD',
            cost: 5.95,
            costType: 'passenger',
            selected: [false],
            contentIcon: '/images/icons/auto-checkin.svg',
            contentHeader: 'Essential Traveler Assist',
            contentDescription:
                `<div className='description description-2'>
                    <div>Travel Stress-Free with more quality time before travel or at the airport. Support Includes:</div>
                    <div>
                        <ul>
                            <li>Flight Watcher</li>
                            <li>Automated Check-ins</li>
                            <li>Weather Alerts</li>
                        </ul>
                    </div>
                </div>`
        },
        {
            id: 'weather-alerts',
            prodctFeaturesId: '',
            isActive: true,
            currency: 'USD',
            cost: 1.95,
            costType: 'trip',
            selected: [false],
            contentIcon: '/images/icons/auto-checkin.svg',
            contentHeader: 'Weather Alerts',
            contentDescription:
                `<div className='description description-1'>
                    Realtime monitoring and  weather updates with alerts through SMS on the day of travel.
                </div>`
        },
        {
            id: 'flight-delay-assistance',
            prodctFeaturesId: '',
            isActive: false,
            currency: 'USD',
            cost: 14.95,
            costType: 'segment',
            selected: [false, false],
            contentIcon: '/images/icons/auto-checkin.svg',
            contentHeader: 'Flight Delay Assistance',
            contentDescription:
                `<div className='description description-1'>
                    If flight is delayed more than 3 hrs, you will get a lounge pass or <sup>USD</sup> 50 cash to spend in the airport. No claim forms is required.
                </div>`,
            segments: ['JFK-MIA', 'MIA-JFK']
        },
        {
            id: 'flight-disruption-guarantee',
            prodctFeaturesId: 'FW_RB',
            isActive: false,
            currency: 'USD',
            cost: 24.95,
            costType: 'segment',
            selected: [false, false],
            contentIcon: '/images/icons/auto-checkin.svg',
            contentHeader: 'Flight Disruption Guarantee',
            contentDescription:
                `<div className='description description-1'>
                    If flight is cancelled, you can jump on rebooking yourself on another airline to reach destination.
                </div>`,
            segments: ['JFK-MIA', 'MIA-JFK']
        }
    ];
}



