import axios, { Axios } from 'axios';
import { IStorage } from '_models/storage.interface';
import { StorageProvider } from './storageProvider.service';
import { AppConstants } from '_constants/App.constants';
import { toast } from 'react-toastify';
import { BehaviorSubjectService } from '_services/behaviorSubject.service';


class Options { authenticated: boolean = false; }

export class ApiService {
    httpProvider: Axios;
    httpProvider2: Axios;
    httpProviderV1: Axios;
    httpProviderBooking: Axios;
    httpProviderMultiFlight: Axios;
    configOptions: any;
    private static instance: ApiService;
    private storageSvc: IStorage = StorageProvider.getStorage();
    private loadingSub = BehaviorSubjectService.loadingSubject;

    private constructor() {
        this.httpProvider = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
        });
        this.httpProvider2 = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL2,
        });
        this.httpProviderV1 = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL_V1,
        });
        this.httpProviderBooking = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL_BOOKING,
        });
        this.httpProviderMultiFlight = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL_MULTIFLIGHT,
        });
        this.httpProvider.interceptors.request.use(
            // this.tokenInterceptor
            async (config) => {
                // console.log(config.url);
                if (config.url && !['fsb-api/fsb/search', 'vendor-mgmnt-api/import/subscriptions'].includes(config.url) && !['refdata-api', 'sql_chat_app', 'samgi_ai_analytics'].includes(config.url.split('/')[0]) && !config.url.includes('/candidatepages')) {
                    this.loadingSub.next(true);
                }
                if (config.url && ['fsb-api', 'refdata-api', 'sql_chat_app', 'samgi_ai_analytics', 'samgi-travel'].includes(config.url.split('/')[0])) {
                    return config;
                }
                return this.tokenInterceptor(config);
            },
            async (error) => {
                this.loadingSub.next(false);
                this.errorHandler(error);
            }
        );
        this.httpProvider.interceptors.response.use(
            async (response) => {
                this.loadingSub.next(false);
                return response;
            },
            async (error) => {
                this.loadingSub.next(false);
                if (!['sql_chat_app'].includes(error.config.url.split('/')[0])) {
                    this.errorHandler(error);
                } else {
                    throw (error);
                }
            }
        );
        this.httpProviderV1.interceptors.request.use(
            async (config) => {
                if (config.url && ['getWeather'].includes(config.url.split('/')[1])) {
                    this.loadingSub.next(true);
                }
                return config;
            },
            async (error) => {
                this.loadingSub.next(false);
                this.errorHandler(error);
            }
        );
        this.httpProviderV1.interceptors.response.use(
            async (response) => {
                this.loadingSub.next(false);
                return response;
            },
            async (error) => {
                this.loadingSub.next(false);
                this.errorHandler(error);
            }
        );
        this.httpProviderBooking.interceptors.request.use(
            async (config) => {
                this.loadingSub.next(true);
                return config;
            },
            async (error) => {
                this.loadingSub.next(false);
                this.errorHandler(error);
            }
        );
        this.httpProviderBooking.interceptors.response.use(
            async (response) => {
                this.loadingSub.next(false);
                return response;
            },
            async (error) => {
                this.loadingSub.next(false);
                this.errorHandler(error);
            }
        );
        this.httpProviderMultiFlight.interceptors.request.use(
            async (config) => {
                this.loadingSub.next(true);
                return config;
            },
            async (error) => {
                this.loadingSub.next(false);
                this.errorHandler(error);
            }
        );
        this.httpProviderMultiFlight.interceptors.response.use(
            async (response) => {
                this.loadingSub.next(false);
                return response;
            },
            async (error) => {
                this.loadingSub.next(false);
                this.errorHandler(error);
            }
        );
    }
    public static getInstance(): ApiService {
        if (!this.instance) {
            this.instance = new ApiService();
        }
        return this.instance;
    }

    tokenInterceptor = (config: any) => {
        const token = this.storageSvc.get(AppConstants.TOKEN.AUTH);
        if (token) config.headers.Authorization = `Bearer ${token}`;
        // if (!this.configOptions?.authenticated) {
        //     delete config.headers.Authorization;
        //     return config;
        // }
        return config;
    };

    errorHandler = async (error: any) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // if (this.storageSvc.get(AppConstants.TOKEN.AUTH)) {
                    //     const newAccess = await this.refreshToken();
                    //     if (newAccess?.token && newAccess?.refresh) {
                    //         this.storageSvc.post(AppConstants.TOKEN.AUTH, newAccess.token)
                    //         this.storageSvc.post(AppConstants.TOKEN.REFRESH, newAccess.refresh)
                    //         error.config.headers['Authorization'] = `Bearer ${newAccess.token}`;
                    //         return axios(error.config);
                    //     } else {
                    //         this.clearLocalStorage();
                    //         toast(newAccess?.message, { type: 'error' });
                    //         setTimeout(() => {
                    //             window.location.href = process.env.PUBLIC_URL + '/login';
                    //         })
                    //     }
                    // } else {
                    //     this.clearLocalStorage();
                    // }

                    this.clearLocalStorage();
                    toast(`Unauthorized access. Please log in again.`, { type: 'error' });
                    if (window.location.pathname !== '/login') {
                        setTimeout(() => {
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        })
                    }
                    break;
                default:
                    if (!(error.config.url.startsWith('bp-doc-matcher/itineraries/') && error.config.url.endsWith('lock')))
                        toast(error.response.data.message, { type: 'error' });
            }
        } else {
            toast(`Unexpected Error.`, { type: 'error' });
        }
    }

    clearLocalStorage = () => {
        this.storageSvc.deleteAll();
    }

    refreshToken = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.storageSvc.get('refreshtoken')}` },
            body: JSON.stringify({ 'uid': this.storageSvc.get('userid'), 'credential': this.storageSvc.get('token') })
        }
        const res = await fetch(`${process.env.REACT_APP_DONATION_ENDPOINT}/auth/refresh`, requestOptions);
        return await res.json();
    }

    get(uri: string, options?: Options) {
        this.configOptions = options;
        return this.httpProvider.get(uri);
    }
    post(uri: string, data?: any, options?: Options) {
        this.configOptions = options;
        return this.httpProvider.post(uri, data);
    }
    put(uri: string, data?: any, options?: Options) {
        this.configOptions = options;
        return this.httpProvider.put(uri, data);
    }
    delete(uri: string, options?: Options) {
        this.configOptions = options;
        return this.httpProvider.delete(uri);
    }

    getCall(uri: string, httpProviderType: 'booking' | 'multiflight' | 'old' | 'type2', options?: Options) {
        switch (httpProviderType) {
            case 'booking':
                return this.httpProviderBooking.get(uri);
            case 'multiflight':
                return this.httpProviderMultiFlight.get(uri);
            case 'old':
                return this.httpProviderV1.get(uri);
            case 'type2':
                return this.httpProvider2.get(uri);

        }
    }
    postCall(uri: string, data: any, httpProviderType: 'booking' | 'multiflight' | 'old' | 'type2', options?: Options) {
        switch (httpProviderType) {
            case 'booking':
                return this.httpProviderBooking.post(uri, data);
            case 'multiflight':
                return this.httpProviderMultiFlight.post(uri, data);
            case 'old':
                return this.httpProviderV1.post(uri, data);
            case 'type2':
                return this.httpProvider2.post(uri, data);
        }
    }
}