
import createTheme from "@mui/material/styles/createTheme";

export class AppConstants {
    // local stroage key
    public static TOKEN = {
        AUTH: 'auth_token',
        REFRESH: 'refresh_token',
        DATA: 'data',
    }

    public static ERROR_TEXT = {
        PAGE_NOT_FOUND: "Page Not Found!",
        SOMETHING_WENT_WRONG: "Something Went Wrong!"
    }

    public static ROLES: any = {
        PLATFORM_ADMIN: 'admin',
        VENDOR: 'vendor',
        OPERATOR: 'checkin',
        OPERATOR_ADMIN: 'checkin'
    }

    public static IMAGE_PATH: string = '/images/icons/';


    public static theme = createTheme({
        palette: {
            primary: {
                main: '#e7c418',
            }
        },
    });

    // local stroage key
    public static LOCAL_STORAGE_KEY = {
        PRODUCTS_FEATURES_DATA: 'productsFeaturesData',
        COUNTRY_DATA: 'countryData',
        ALL_AIRLINES: 'allAirlines',
        ALL_AIRPORTS: 'allAirports',
        UPCOMING_TRIPS_DISPLAY_TYPE: 'upcomingTripsDisplayType',
    }
}